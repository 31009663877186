import { RFValue } from "react-native-responsive-fontsize";

export default {
  COLORS: {
    ALERT: "#ec1414",
    ICON: '#D2C0F6', //COR DOS CADEADOS E DAS SETAS PARA O LADO
    ICON_HEADER_PURCHASE: "#D2C0F6", //COR DAS SETINHAS 
    BACKGROUND_HEADER: "none", //COR DE TODOS OS CABEÇALHOS
    //TELA CLICKCOURSE, PLANS e SIGNUP
    ICON_HEADER: "#5E1492",  //COR DA PALAVRA ESCOLHER COMPRA, CADASTRAR-SE E COMPLETAR PAGAMENTO e VOLTAR da EDITPROFILE

    //CORES GERAIS
    PRIMARY_900: "#260C59", //COR PRINCIPAL DOS DETALHES DO APP - CHECKBOX, FECHAR TERMOS DE USO, ETC
    PRIMARY_800: "#5E1492", //COR PRINCIPAL MAIS CLARA PARA INPUT LOGIN E ACTIVITY INDICATOR
    PRIMARY_700: "#D2C0F6", //COR MAIS CLARA DO CLIENTE = COR PRINCIPAL MAIS CLARA PARA INPUT LOGIN E ACTIVITY INDICATOR
    TEXT_BUTTON: "#FFFFFF", //USADA PARA O TEXTO DOS BOTÕES LOGIN, CLICKCOURSE, SIGNUP - COR DE FUNDO É A PRIMARY_900

    //CORES DO DRAWER
    DRAWER_SELECTED: "#D2C0F6", //COR DRAWER CLICADO E DO TEXTO DO DRAWER

    //TELAS: LOGIN, CLICKCOURSE, SIGNUP E PLANS
    BACKGROUND_ABOUT: "#FFFFFF", //COR PARA A ABOUT, DRAWER, CLICK COURSE, PLANS, EDITPROFILE, LOGIN E CADASTRO
    TITLE_ABOUT: '#260C59', //TAMANHOS EXTRABIG, BIG E STANDARD
    TEXT_ABOUT: '#260C59', //TAMANHOS MEDIUM, SMALL E EXTRASMALL

    //TELA TERMOS DE USO
    BACKGROUND_TERMS: "#FFFFFF", //COR PARA O FUNDO DOS TERMOS

    //PLANS
    S5_BACKGROUND: "#FFFFFF",
    S5_BACKGROUND_SELECTED: "#D2C0F6", //COR DE FUNDO DO ITEM SELECIONADO DA FLATLIST MENSAL, TRI, ETC
    S5_BACKGROUND_NO_SELECTED: "white", //COR DE FUNDO DO ITEM NAO SELECIONADO DA FLATLIST MENSAL, TRI, ETC
    S5_TEXT_SELECTED: "#5E1492", //COR DO TEXTO DO ITEM SELECIONADO DA FLATLIST MENSAL, TRI, ETC
    S5_TEXT_NO_SELECTED: "#260C59", //COR DO TEXTO DO ITEM NAO SELECIONADO DA FLATLIST MENSAL, TRI, ETC
    
    //TELA MAIN
    ICON_HEADER_CLICKCLASS: "#D2C0F6", //COR DO ÍCONE VOLTAR DA CLICKCLASS
    ICON_HEADER_MAIN: "#D2C0F6", //COR DO ICONE DRAWER
    BACKGROUND_MAIN: "#260C59", //COR PARA A MAIN E CLICKCLASS
    TITLE_OVERPHOTO_MAIN: "#D2C0F6", //USADA PARA O TÍTULO E SUBTÍTULO DA MAIN
    TITLE_MAIN: "#D2C0F6", //USADA PARA PARA OS TAMANHOS EXTRABIG, BIG E STANDARD
    TEXT_MAIN: "#D2C0F6", //USADA PARA PARA OS TAMANHOS MEDIUM, SMALL E EXTRASMALL

    //FOOTER TELA ABOUT E DRAWER
    BACKGROUND_FOOTER: "white", //COR DO RODAPÉ
    TEXT_FOOTER: "#260C59", //USADA PARA O TEXTO DO RODAPÉ

    GRADIENT_MAIN: [
      "rgba(0,0,0,0.5)",
      "rgba(0,0,0,0.1)",
      "rgba(0,0,0,0.1)",
      "#260C59",
    ],

    //BOTÕES ARQUIVAR E PUBLICAR
    BACKGROUND_PUBLISH: "#D2C0F6", //COR DO RODAPÉ
    BACKGROUND_ARCHIVE: "#5E1492", //COR DO RODA
    TEXT_PUBLISH: "#260C59", //USADA PARA O TEXTO DO RODAPÉ
    TEXT_ARCHIVE: "#D2C0F6", //USADA PARA O TEXTO DO RODAPÉ
  },

  FONTFAMILY: {
    BOLD: "text_Bold",
    MEDIUM: "text_Medium",
    REGULAR: "text_Regular",
    LIGHT: "text_Light",
    TITLE: "text_Title",
    SUBTITLE: "text_Subtitle",
    TEXTS: "text_Texts"
  },
  FONTSIZE: {
    EXTRABIG: RFValue(30, 980), //USADA 1X NA COURSES
    BIG: 26,
    STANDARD: 20,
    MEDIUM: 18,
    SMALL: 16,
    EXTRASMALL: 13,
  },
};